import React, { useState, useLayoutEffect, useEffect, useRef } from "react"
import styled from "styled-components"
import Img from "gatsby-image"
//Sections
import { Section } from "./sections.js"
//Elements
import { SectionGrid, QuoteGrid, Aside3xGrid } from "../elements/grids.js"
import {
  SectionCaption,
  SectionItemTitle,
  SectionItemBody,
} from "../typography.js"
import { AsideTitle, AsideDescription, SectionTitle } from "../typography.js"

import { Carousel } from "react-responsive-carousel"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

const MediaContainer = styled.video`
  grid-area: ${props => props.gridArea};
  width: 100%;
  height: auto;
  background: transparent;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3);
`
const ImgContainer = styled.img`
  grid-area: ${props => props.gridArea};
  width: 70%;
  height: auto;
  background: transparent;
  padding: 5% 5%;
  margin: auto;
`
const Lorem =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text"
const Lorem2 =
  "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text"

const FigureText = styled(SectionTitle)`
  @media (max-width: 400px) {
    display: none;
  }
`
export const ValuePropLeft = ({
  caption,
  title,
  description = Lorem,
  poster,
  source,
  figure,
  refs,
  wide,
  sectionColor,
}) => {
  return (
    <Section wide={wide} backgroundColor={sectionColor}>
      <SectionGrid>
        {source ? (
          <ImgContainer src={source} alt={""} gridArea={" 1 / 2 / 4 / 3"} />
        ) : figure ? (
          <FigureText
            center="center"
            fontSize="70px"
            style={{ gridArea: " 1 / 2 / 4 / 3", margin: "auto" }}
          >
            {figure}
          </FigureText>
        ) : (
          <MediaContainer
            ref={refs}
            gridArea={" 1 / 2 / 4 / 3"}
            autoplay={true}
            muted={true}
            loop={true}
            playsinline={true}
            poster={
              source
                ? source
                : "https://cdn.sketchapp.com/assets/pages/home/v70-app-window.jpg"
            }
          >
            {source ? (
              <img src={source} alt={""} />
            ) : (
              <source
                src="https://cdn.sketchapp.com/assets/pages/home/v70-app-window.mp4"
                type="video/mp4"
              />
            )}
          </MediaContainer>
        )}
        <div style={{ gridArea: "1 / 1 / 2 / 2" }}>
          <SectionCaption>{caption || "LEARN EASILY"}</SectionCaption>
          <SectionItemTitle>
            {title
              ? Array.isArray(title)
                ? title.map((segment, index) => {
                    return (
                      <React.Fragment key={"heroBodyPart" + index}>
                        {segment}
                      </React.Fragment>
                    )
                  })
                : title
              : "Bottom-up Instruction"}
          </SectionItemTitle>
        </div>
        <SectionItemBody style={{ gridArea: "2 / 1 / 3 / 2" }}>
          {Array.isArray(description)
            ? description.map((segment, index) => {
                return (
                  <React.Fragment key={"heroBodyPart" + index}>
                    {segment}
                  </React.Fragment>
                )
              })
            : description}
        </SectionItemBody>
      </SectionGrid>
    </Section>
  )
}

export const ValuePropRight = ({
  caption,
  title,
  description = Lorem,
  poster,
  source,
  figure,
  refs,
  wide,
  sectionColor,
}) => {
  //Same as ValuePropLeft but different SectionGrid css && different gridArea

  return (
    <Section wide={wide} backgroundColor={sectionColor}>
      <SectionGrid textLocation="right">
        <div style={{ gridArea: "1 / 2 / 2 / 3" }}>
          <SectionCaption>{caption || "LEARN EASILY"}</SectionCaption>
          <SectionItemTitle>
            {title || "Bottom-up Instruction"}
          </SectionItemTitle>
        </div>
        <SectionItemBody style={{ gridArea: "2 / 2 / 3 / 3" }}>
          {Array.isArray(description)
            ? description.map((segment, index) => {
                return (
                  <React.Fragment key={"heroBodyPart" + index}>
                    {segment}
                  </React.Fragment>
                )
              })
            : description}
        </SectionItemBody>
        {source ? (
          <ImgContainer src={source} alt={""} gridArea={"1 / 1 / 3 / 2"} />
        ) : figure ? (
          <FigureText
            center="center"
            fontSize="70px"
            style={{ gridArea: "1 / 1 / 3 / 2", margin: "auto" }}
          >
            {figure}
          </FigureText>
        ) : (
          <MediaContainer
            ref={refs}
            gridArea={"1 / 1 / 3 / 2"}
            autoplay={true}
            muted={true}
            loop={true}
            playsinline={true}
            poster={
              source
                ? source
                : "https://cdn.sketchapp.com/assets/pages/home/v70-app-window.jpg"
            }
          >
            {source ? (
              <img src={source} alt={""} />
            ) : (
              <source
                src="https://cdn.sketchapp.com/assets/pages/home/v70-app-window.mp4"
                type="video/mp4"
              />
            )}
          </MediaContainer>
        )}
      </SectionGrid>
    </Section>
  )
}

const AsideContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  grid-area: ${props => props.gridArea};
  border: 1px solid #ccc;
  padding: 15px 15px ${props => (props.description ? "1em" : "0px")} 15px;
  border-radius: 12px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25),
    0 8px 16px -8px rgba(0, 0, 0, 0.3);
  text-align: ${props => props.float || "left"};
`

const AsideImg = styled.img`
  height: 70px;
  width: auto;
  margin: 0 auto 1em auto;
  position: relative;
  // left: 100px;
`
export const ValuePropsX3 = ({
  float,
  caption,
  title,
  description = Lorem,
  asides,
  images,
  refs,
  id,
  sectionColor,
  wide,
  style,
}) => {
  const asideGridAreas = ["1 / 1 / 2 / 2", "1 / 2 / 2 / 3", "1 / 3 / 2 / 4"]
  return (
    <Section
      id={id}
      ref={refs}
      backgroundColor={sectionColor}
      wide={wide}
      style={style}
    >
      {caption && caption.length ? (
        <SectionCaption center={float || "right"}>{caption}</SectionCaption>
      ) : null}
      {title && title.length ? (
        <SectionTitle center={float || "right"} paddingBottom={"2em"}>
          {title}
        </SectionTitle>
      ) : null}

      <Aside3xGrid>
        {asides && asides.length
          ? asides.map((item, index) => (
              <AsideContainer
                key={`${item.title} asideContainer ${index}`}
                gridArea={asideGridAreas[index]}
                description={!!item.description}
                float={float}
              >
                <>
                  {item.caption ? (
                    <SectionCaption center={float || "left"}>
                      {item.caption}
                    </SectionCaption>
                  ) : null}
                  {item.src ? <AsideImg src={item.src} alt={item.alt} /> : null}
                  <AsideTitle
                    center={float || "left"}
                    children={
                      Array.isArray(item.title)
                        ? item.title.map((segment, index) => {
                            return (
                              <React.Fragment key={"heroBodyPart" + index}>
                                {segment}
                              </React.Fragment>
                            )
                          })
                        : item.title
                    }
                  />
                </>
                {item.description ? (
                  <AsideDescription children={item.description} />
                ) : null}
              </AsideContainer>
            ))
          : null}
      </Aside3xGrid>
    </Section>
  )
}
const FlexWrap = styled(Aside3xGrid)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin: 50px auto;
`
const AsideContainerV2 = styled(AsideContainer)`
  width: auto;
  padding: 0 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 3.5em;
  border-left: 3px solid #f50057;
`

const MetricWrap = styled.div`
  display: flex;
  height: 100%;
  box-sizing: border-box;
  border-radius: inherit;
  color: #f50057 !important;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  padding-left: 0px;
`
export const ValuePropsX3NoDescrip = ({
  float,
  caption,
  title,
  asides,
  wide,
  style,
  sectionColor,
}) => {
  const asideGridAreas = ["1 / 1 / 2 / 2", "1 / 2 / 2 / 3", "1 / 3 / 2 / 4"]
  return (
    <Section backgroundColor={sectionColor} wide={wide} style={style}>
      {caption && caption.length ? (
        <SectionCaption center={float || "right"}>{caption}</SectionCaption>
      ) : null}
      {title && title.length ? (
        <SectionTitle center={float || "right"} paddingBottom={"2em"}>
          {title}
        </SectionTitle>
      ) : null}

      <FlexWrap>
        {asides && asides.length
          ? asides.map((item, index) => (
              <AsideContainerV2
                key={`${item.title} asideContainer ${index}`}
                gridArea={asideGridAreas[index]}
                description={!!item.description}
                float={float}
              >
                <MetricWrap>
                  <AsideTitle
                    center="center"
                    children={item.metric}
                    style={{ paddingBottom: "0px" }}
                  />
                </MetricWrap>
                <>
                  <AsideTitle
                    center={float || "left"}
                    children={item.title}
                    style={{
                      padding: "5px 0px",
                    }}
                  />
                </>
              </AsideContainerV2>
            ))
          : null}
      </FlexWrap>
    </Section>
  )
}

const QuoteImg = styled(Img)`
  display: block;
  grid-area: ${props => props.gridArea};
  width: 40%;
  height: auto;
  border-radius: 50%;
  border: 1px solid white;
  background-color: none;
  margin: auto;
  filter: grayscale(100%);
`

const QuoteDescrip = styled(SectionItemBody)`
  grid-area: ${props => props.gridArea};
  text-align: center;
  font-weight: 300;
  line-height: 2;
  font-size: 25px;
  font-style: italic;

  &::before {
    content: '"  ';
    font-weight: 200;
    font-size: 30px;
    font-style: italic;
    line-height: 1;
  }
  &::after {
    content: '  "';
    font-weight: 200;
    font-size: 30px;
    font-style: italic;
    line-height: 1;
  }
`

const ImgSubText = styled(SectionItemBody)`
  grid-area: ${props => props.gridArea};
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  font-style: none;
  width: 100%;
  display: block;
  margin-top: 1em;
  opacity: 0.5;
`
export const Quote = ({
  wide,
  sectionColor,
  children,
  quoteSubText,
  src,
  alt,
  fluid,
}) => {
  return (
    <Section wide={wide} backgroundColor={sectionColor}>
      <QuoteGrid>
        <div
          style={{ gridArea: "1 / 1 / 2 / 2", padding: "none", margin: "none" }}
        >
          <QuoteDescrip style={{ gridArea: "2 / 1 / 3 / 2" }}>
            {children}
          </QuoteDescrip>
        </div>

        <div gridarea={" 1 / 2 / 4 / 3"} style={{ margin: "auto" }}>
          <QuoteImg
            fluid={fluid}
            src={src}
            alt={""}
            gridArea={" 1 / 2 / 4 / 3"}
          />
          <ImgSubText>{quoteSubText}</ImgSubText>
        </div>
      </QuoteGrid>
    </Section>
  )
}

const TallOuterContainer = styled.div.attrs(({ dynamicHeight }) => ({
  style: { height: `${dynamicHeight}px` },
}))`
  position: relative;
  width: 100%;
  @media (max-width: 500px) {
    display: none;
  }
`

const StickyInnerContainer = styled.div`
  position: sticky;
  top: 0;
  height: 90vh;
  width: 100%;
  overflow-x: hidden;
`

const HorizontalTranslateContainer = styled.div.attrs(({ translateX }) => ({
  style: { transform: `translateX(${translateX}px)` },
}))`
  position: absolute;
  height: 100%;
  will-change: transform;
`

const CardsContainer = styled.div`
  position: relative;
  height: 100%;
  padding: 0 0 0 33vw;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
`

const SampleCard = styled.div`
  position: relative;
  height: 300px;
  width: 40%;
  margin-right: 75px;
  flex-shrink: 0;
  @media (max-width: 1000px) {
    width: 40vw;
  }
  @media (max-width: 800px) {
    width: 70vw;
  }
`
const PostItWrap = styled(SampleCard)`
  width: 100%;
  height: auto;
  opacity: 0.2;
  transition: opacity 0.3s;
`

export const HorizontalScrollSection = ({
  children,
  asides,
  sectionColor,
  postIt,
}) => {
  //For horizontal scroll when scrolling vertically

  const [dynamicHeight, setDynamicHeight] = useState(null)
  const [translateX, setTranslateX] = useState(0)
  const [checkObjectScroll, setCheck] = useState(false)

  const [postItLocations, setLocations] = useState([])
  const [currentHighlight, setHighlight] = useState(0)
  const [prevNoteIndex, setPrevIndex] = useState(null)
  const [nextNoteIndex, setNextIndex] = useState(1)
  const [prevHighlight, setPrevHighlight] = useState(null)

  const containerRef = useRef(null)
  const objectRef = useRef(null)
  const postItRefs = useRef([])

  const resizeHandler = () => {
    handleDynamicHeight(objectRef, setDynamicHeight)
  }
  const calcDynamicHeight = objectWidth => {
    const vw = typeof window !== "undefined" ? window.innerWidth : 0
    const vh = typeof window !== "undefined" ? window.innerHeight : 0
    return objectWidth - vw + vh + 250
  }
  const handleDynamicHeight = (ref, setDynamicHeight) => {
    const objectWidth =
      ref.current && ref.current.scrollWidth ? ref.current.scrollWidth : 0
    const dynamicHeight = calcDynamicHeight(objectWidth * 2.8)
    setDynamicHeight(dynamicHeight)
  }
  const handleHighlightState = num => {
    setHighlight(num)
  }
  const handleLocations = () => {
    if (typeof window !== "undefined") {
      let prev = 0
      const vw = typeof window !== "undefined" ? window.innerWidth : 0
      let state = postItRefs.current.map((post, index) => {
        if (post) {
          let current = index === 0 ? 0 : post.clientWidth
          let result = current + prev
          prev = result
          return result
        }
      })

      setLocations(state)
    }
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      handleDynamicHeight(objectRef, setDynamicHeight)
    }
  }, [])

  useScrollPosition(
    ({ prevPos, currPos }) => {
      //Allows the section to translate when it reaches the right scroll height
      if (currPos.y === 0) {
        setCheck(true)
        if (postItLocations.length !== children.length) {
          handleLocations()
          positionLocator(offsetTop)
        }
        const offsetTop =
          containerRef &&
          containerRef.current !== null &&
          containerRef.current.offsetTop
            ? -containerRef.current.offsetTop / 3.5 //Divide by 3.5 to slow scroll speed.
            : 0
        //Will multiply elsewhere by 3.5 to equalize
        setTranslateX(offsetTop)

        changePostItHighlight(offsetTop)
      } else {
        setCheck(false)
      }
    },
    [
      postItLocations,
      currentHighlight,
      prevNoteIndex,
      nextNoteIndex,
      translateX,
      prevHighlight,
    ],
    containerRef
  )

  const positionLocator = currPos => {
    if (postItLocations) {
      postItLocations.forEach((startPosition, index) => {
        let nextPosition =
          postItLocations[index + 1] === undefined
            ? null
            : postItLocations[index + 1]
        let isHigherThanStartPosition = currPos > startPosition
        let isLowerThanNextPosition = nextPosition
          ? currPos < nextPosition
          : true

        if (isHigherThanStartPosition && isLowerThanNextPosition) {
          if (index !== currentHighlight) {
            setPrevHighlight(currentHighlight)
            setHighlight(index)
            setPrevIndex(index > 0 ? index - 1 : null)
            setNextIndex(postItLocations[index + 1] ? index + 1 : null)
          }
        }
      })
    }
  }
  const changePostItHighlight = currPos => {
    if (currentHighlight !== prevHighlight) {
      let isNextNote =
        nextNoteIndex !== null
          ? Math.abs(currPos) < postItLocations[nextNoteIndex]
          : false
      let currNoteStartPoint = postItLocations[currentHighlight]
      let isPreviousNote =
        prevNoteIndex !== null
          ? Math.abs(currPos) > postItLocations[prevNoteIndex]
          : false

      if (nextNoteIndex === null) {
        postItRefs.current[currentHighlight].style.opacity = 1
        postItRefs.current[prevNoteIndex].style.opacity = 0.2
      } else if (prevNoteIndex === null) {
        postItRefs.current[currentHighlight].style.opacity = 1
        postItRefs.current[nextNoteIndex].style.opacity = 0.2
      } else if (isNextNote) {
        postItRefs.current[currentHighlight].style.opacity = 1
        postItRefs.current[prevNoteIndex].style.opacity = 0.2
        postItRefs.current[nextNoteIndex].style.opacity = 0.2
      } else if (isPreviousNote) {
        postItRefs.current[nextNoteIndex].style.opacity = 0.2
        postItRefs.current[currentHighlight].style.opacity = 1
        postItRefs.current[prevNoteIndex].style.opacity = 0.2
      }
    }
    positionLocator(Math.abs(currPos))
  }

  const applyScrollListener = (containerRef, currPos) => {
    const offsetTop =
      containerRef &&
      containerRef.current !== null &&
      containerRef.current.offsetTop
        ? -containerRef.current.offsetTop / 3.5 //Divide by 3.5 to slow scroll speed.
        : 0
    positionLocator(-currPos / 3.5)
  }

  const handlePostItRefs = (el, index) => {
    if (postItRefs.current.length < children.length) {
      postItRefs.current.push(el)
    }
  }

  return (
    <TallOuterContainer
      style={{ backgroundColor: sectionColor }}
      dynamicHeight={dynamicHeight}
    >
      <StickyInnerContainer ref={containerRef}>
        <HorizontalTranslateContainer translateX={translateX} ref={objectRef}>
          <CardsContainer>
            {children.map((postIt, index) => {
              return (
                <PostItWrap
                  key={`_postIt_${index}`}
                  ref={el => handlePostItRefs(el, index)}
                >
                  {postIt}
                </PostItWrap>
              )
            })}
          </CardsContainer>
        </HorizontalTranslateContainer>
      </StickyInnerContainer>
    </TallOuterContainer>
  )
}
const SRLWrap = styled.div`
  width: 90%;
  display: flex;
  flex-flow: column nowrap;
  margin: 30px auto;
  @media (max-width: 375px) {
    display: none;
  }
  @media (min-width: 500px) {
    display: none;
  }
  @media (max-width: 500px) {
    display: block;
  }
`
const SRLContent = styled.div`
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  font-family: "Kalam", cursive;
  font-size: 1.25rem;
  color: #e3e3e3;
  color: black;
  margin: 60px auto;
`
export const SimpleReviewLayout = ({ children, sectionColor }) => {
  return (
    <SRLWrap>
      {children.map((postIt, index) => {
        return <SRLContent key={`_SRL_postIt_${index}`}>{postIt}</SRLContent>
      })}
    </SRLWrap>
  )
}

const SwipeCard = styled.section`
  display: flex;
  flex-flow: row nowrap;
  width: 80%;
  margin: 0px auto;
  margin-bottom: 45px;
  padding: 16px;
  height: auto;
  text-align: left;
  align-items: center;
  border: 1px solid #ccc;
  position: relative;
  top: 15px;
  @media (max-width: 600px) {
    flex-flow: row wrap;
  }
`
export const SwipeSection = ({ title, asides, sectionColor }) => {
  return (
    <Section
      wide
      style={{
        flexFlow: "column nowrap",
        paddingBottom: "0px",
      }}
    >
      <SectionTitle
        center="center"
        style={{
          marginBottom: "140px",
          marginTop: "0px",
        }}
      >
        {title}
      </SectionTitle>
      <div style={{ width: "95%", margin: "0 auto", display: "block" }}>
        <Carousel
          showThumbs={false}
          showArrows={true}
          useKeyboardArrows={true}
          autoPlay={false}
          stopOnHover={true}
          swipeable={true}
          emulateTouch={true}
          showStatus={false}
          swipeScrollTolerance={7}
          interval={5500}
          transitionTime={1000}
        >
          {asides &&
            asides.map((item, index) => (
              <SwipeCard key={`${item.title} asideContainer ${index}`}>
                <AsideImg
                  src={item.src}
                  alt={item.alt}
                  style={{ height: "30%", width: "15vw", marginRight: "16px" }}
                />
                <div style={{ width: "100%" }}>
                  <AsideTitle children={item.title} />
                  <AsideDescription
                    style={{ width: "100%" }}
                    children={
                      item.description
                        ? Array.isArray(item.description)
                          ? item.description.map((segment, index) => {
                              return (
                                <React.Fragment key={"heroBodyPart" + index}>
                                  {segment}
                                </React.Fragment>
                              )
                            })
                          : item.description
                        : Lorem2
                    }
                  />
                </div>
              </SwipeCard>
            ))}
        </Carousel>
      </div>
    </Section>
  )
}
